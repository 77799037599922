import styled from "styled-components";
import { motion } from "framer-motion";

export const WhitePaperWrapper = styled(motion.div)`
  width: 100%;
  background-color: #fff;
  z-index: 2;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @media (min-width: 1023px) {
    max-width: 550px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;
  }
`;
