export const postMessage = ({ type, status, data }) => {
  const msg = JSON.stringify({
    EventStatus: status,
    EventType: type,
    EventData: data,
  });

  if (window) {
    try {
      window.postMessage(msg, "*");
      window.parent.postMessage(msg, "*");
    } catch (e) {
      console.log("error postMessage default", e);
    }

    try {
      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.ios
      ) {
        window.webkit.messageHandlers.ios.postMessage(msg);
      }
    } catch (e) {
      console.log("error postMessage for ios", e);
    }

    try {
      window.Android && window.Android.dispatch(msg);
    } catch (e) {
      console.log("error postMessage for android", e);
    }
  }

  try {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(msg);
    }
  } catch (e) {
    console.log("error postMessage for react native", e);
  }
};

export function isSuperApp() {
  const searchParams = new URLSearchParams(window.location.search);

  const source = searchParams.get("source") || "";

  return source === "superapp";
}
