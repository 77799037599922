import React, { useState } from "react";
import "./App.css";
import Submission from "./components/submission";
import WhitePaper from "./components/whitePaper";
import ImageUpload from "./components/imageUpload";
import Greet from "./components/greet";
import StatusPage from "./components/statusPage";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import Submission2 from "./components/submition2";
import { postMessage } from "./utils";

// const variants = { closed: {}, open: {} };

const page0 = {
  height: "300px",
};

const page1 = {
  height: "500px",
};

const page2 = {
  height: "500px",
};

const page3 = {
  height: "650px",
};

const bodyVariant = {
  open: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.4,
      type: "spring",
      mass: 1,
      stiffness: 200,
      damping: 33,
    },
  },
  closed: {},
};

const getInitialState = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const firstName = searchParams.get("firstName") || "";
  const lastName = searchParams.get("lastName") || "";
  const tktNumber = searchParams.get("ticketIdentifier") || "";
  const source = searchParams.get("source") || "";

  if (source === "superapp") {
    return { page: 2, name: `${firstName} ${lastName}`, tktNumber };
  }

  return { page: 0, name: "", tktNumber: "" };
};

function App() {
  const initialState = getInitialState();
  const [page, setPage] = useState(initialState.page);
  const [didError, setDidError] = useState(false);
  const [firstPageInfo, setFirstPageInfo] = useState({
    tktNumber: initialState.tktNumber,
    name: initialState.name,
    Location: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const queryStr = urlParams.get("ticketId");

  return (
    <WhitePaper
      initial={false}
      transition={{
        type: "spring",
        stiffness: 400,
        damping: 40,
      }}
      animate={
        page === 0 ? page0 : page === 1 ? page1 : page === 2 ? page2 : page3
      }
    >
      <AnimatePresence exitBeforeEnter={true}>
        <motion.div
          initial="closed"
          animate="open"
          exit="closed"
          key={page}
          variants={bodyVariant}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          {page === 0 && (
            <Greet
              onNext={(form) => {
                setFirstPageInfo(form);
                setPage(1);
              }}
            />
          )}
          {page === 1 && (
            <Submission
              tktId={queryStr}
              onNext={(form) => {
                setFirstPageInfo(form);
                setPage(2);
              }}
            />
          )}
          {page === 2 && (
            <Submission2
              onNext={(form) => {
                setFirstPageInfo((prev) => {
                  return { ...prev, Location: form };
                });
                setPage(3);
              }}
            />
          )}
          {page === 3 && (
            <ImageUpload
              onBack={(status) => {
                if (status) {
                  setPage(page - 1);
                }
              }}
              onNext={(base64) => {
                axios
                  .post(
                    "https://api.tkt.ge/v2/tickets/verify?api_key=dad88b07-cd98-4a26-9171-59de30f3318b",
                    {
                      TicketIdentifier: firstPageInfo.tktNumber,
                      Name: firstPageInfo.name,
                      Photo: `${base64}=`,
                      Address: firstPageInfo.Location,
                    }
                  )
                  .then((res) => {
                    setPage(4);

                    if (res.data.Success) {
                      setTimeout(() => {
                        postMessage({
                          type: "mta_verify_finish",
                          status: "success",
                        });
                      }, 3500);
                      setDidError(false);
                    } else {
                      postMessage({
                        type: "mta_verify_finish",
                        status: "error",
                        data: res.data.Errors[0].Description,
                      });
                      setErrMsg(res.data.Errors[0].Description);
                      setDidError(true);
                    }
                  })
                  .catch(() => {
                    postMessage({
                      type: "mta_verify_finish",
                      status: "error",
                      data: "Verification failed",
                    });
                    setDidError(true);
                  });
              }}
            />
          )}
          {page === 4 && <StatusPage errMsg={errMsg} didError={didError} />}
        </motion.div>
      </AnimatePresence>
    </WhitePaper>
  );
}

export default App;
