import React from "react";
import { Button, SuperAppButton } from "./style";
import { isSuperApp } from "../../../utils";

export default (props) => {
  const ButtonComponent = isSuperApp() ? SuperAppButton : Button;

  return (
    <ButtonComponent {...props}>
      {props.children}
      {props.hasArrow && !isSuperApp() && <img src="/icons/arrow.svg" />}
    </ButtonComponent>
  );
};
