import React, { useState } from "react";
import {
  EventTitle,
  OrderP,
  // StaticDiv,
  CustomDiv,
  CheckSection,
  CheckItem,
} from "./style";
import Button from "../common/button";
import { motion } from "framer-motion";

// const bodyVariant = {
//   open: {
//     y: 0,
//     transition: {
//       staggerChildren: 0.1,
//       delayChildren: 0.4,
//       type: "spring",
//       mass: 1,
//       stiffness: 200,
//       damping: 33,
//     },
//   },
//   closed: { y: 180 },
// };

const itemVariant = {
  closed: { opacity: 0, y: 15 },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
};

const Submission2 = ({ onNext }) => {
  const [activeCheck, setActiveCheck] = useState(false);
  const [locationData, setLocationData] = useState("");
  const checkForNext = () => {
    if (locationData.length > 0) {
      onNext(locationData);
    }
  };
  return (
    <>
      <EventTitle style={{ marginBottom: 0 }} variants={itemVariant}>
        ბარათის მიწოდების სერვისი
      </EventTitle>
      <OrderP variants={itemVariant}>
        აირჩიე შენთვის სასურველი მიწოდების სერვისი
      </OrderP>
      <CheckSection>
        <CheckItem
          onClick={() => {
            setActiveCheck(false);
            setLocationData("");
          }}
          variants={itemVariant}
        >
          <img
            src={`/icons/${activeCheck ? "disabledCheck" : "activeCheck"}.svg`}
            alt=""
          />
          <OrderP
            style={{
              color: "black",
              marginTop: 0,
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            მომიტანე მისამართზე
          </OrderP>
        </CheckItem>
        {/* <CheckItem
          onClick={() => {
            setLocationData("თბილისი, რუსთაველის #5");
            setActiveCheck(true);
          }}
          variants={itemVariant}
        >
          <img
            src={`/icons/${!activeCheck ? "disabledCheck" : "activeCheck"}.svg`}
            alt=""
          />
          <OrderP
            style={{
              color: "black",
              marginTop: 0,
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            ადგილზე აღება
          </OrderP>
        </CheckItem> */}
      </CheckSection>
      {/* {activeCheck && (
        <StaticDiv>
          <EventTitle style={{ marginBottom: 0 }} variants={itemVariant}>
            ფილიალის ადგილმდებარეობა
          </EventTitle>
          <motion.div
            variants={itemVariant}
            style={{
              display: "flex",
              gap: "10px",
              marginTop: "1rem",
            }}
          >
            <img
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
              src={`/icons/location.svg`}
              alt=""
            />
            <motion.div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <EventTitle
                style={{
                  marginBottom: 0,
                  fontSize: "15px",
                  alignItems: "center",
                  marginTop: "-3px",
                }}
                variants={itemVariant}
              >
                გალერეა თბილისი
              </EventTitle>
              <OrderP
                style={{
                  color: "black",
                  marginTop: 0,
                  fontWeight: 400,
                  fontSize: 12,
                }}
                variants={itemVariant}
              >
                თბილისი, რუსთაველის #5
              </OrderP>
            </motion.div>
          </motion.div>
        </StaticDiv>
      )} */}
      {!activeCheck && (
        <CustomDiv style={{ marginBottom: 20 }}>
          <EventTitle style={{ marginBottom: 0 }} variants={itemVariant}>
            მიუთითეთ თქვენი ზუსტი მდებარეობა
          </EventTitle>
          <OrderP variants={itemVariant}>
            მიწოდება ხორციელდება მხოლოდ თბილისის მაშტაბით
          </OrderP>
          <motion.div
            variants={itemVariant}
            style={{
              display: "flex",
              gap: "10px",
              marginTop: "1rem",
              background: "#F7F7FA",
              padding: "1rem 1.3rem",
              borderRadius: "9px",
            }}
          >
            <img src={`/icons/location1.svg`} alt="" />
            <motion.input
              style={{
                outline: "none",
                border: "none",
                background: "#F7F7FA",
                width: "100%",
                fontFamily: "FiraGO",
              }}
              variants={itemVariant}
              title="მისამართი"
              autoFocus
              onChange={(e) => setLocationData(e.target.value)}
              // value={locationData}
            />
          </motion.div>
        </CustomDiv>
      )}
      {/* <Input
        info
        value
        variants={itemVariant}
        title="სახელი და გვარი"
        onChange={setFullName}
      />
      <Input
        info
        value
        variants={itemVariant}
        title="პირადი ნომერი"
        onChange={setPId}
      />
      <Input
        variants={itemVariant}
        title="ბილეთის  ნომერი"
        info
        onChange={setTktNumber}
        value={tktNumber}
      /> */}
      <Button
        variants={itemVariant}
        style={{ marginTop: "auto" }}
        hasArrow
        onClick={() => {
          if (locationData.length > 0) {
            checkForNext();
          }
        }}
      >
        შემდეგი
      </Button>
    </>
  );
};

export default Submission2;
